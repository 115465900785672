import React from 'react'

import { BasicLayout } from '../../components/BasicLayout'
import { AuthRoute } from '../../components/auth/AuthRoute'
import { SessionTimeoutModal } from '../../components/auth/SessionTimeoutModal'
import { PaymentOrdersDashboard } from '../../components/payments/PaymentOrdersDashboard'
import { AccountType } from '../../requests'

const PaymentOrderManagement = () => {
  return (
    <>
      <SessionTimeoutModal accountType={AccountType.User} />
      <AuthRoute>
        <BasicLayout>
          <PaymentOrdersDashboard />
        </BasicLayout>
      </AuthRoute>
    </>
  )
}

export default PaymentOrderManagement
export const Head = () => <title>Datacapt - Payment Order Management</title>
