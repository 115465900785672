import { create } from 'zustand'

import { PaymentOrder, PaymentOrderSorter, PaymentOrderStatus, SelectionKeys } from '../../../requests'
import { TableRecord } from '../../../utils'

interface onPaymentOrdersPageSuccessParams {
  paymentOrders: TableRecord<PaymentOrder>[]
  allPaymentOrdersCount: number
  currentPage: number
}

interface PaymentOrdersDashboardState {
  isFetchingPaymentOrders: boolean
  isSearching: boolean
  isMakePaymentsModalOpened: boolean
  isErrorModalOpened: boolean
  isMakingPayments: boolean
  isEverythingSelected: boolean
  sorter: PaymentOrderSorter
  selectedPaymentOrders: SelectionKeys
  paymentOrders: TableRecord<PaymentOrder>[]
  allPaymentOrdersCount: number
  paymentOrderToView: PaymentOrder
  currentPage: number
  search: string
  filters: Record<string, string[]>
  status: PaymentOrderStatus
  setIsFetchingPaymentOrders: (isFetchingPaymentOrders: boolean) => void
  setIsMakePaymentsModalOpened: (isMakePaymentsModalOpened: boolean) => void
  setIsErrorModalOpened: (isErrorOpened: boolean) => void
  setIsMakingPayments: (isMakingPayments: boolean) => void
  setIsEverythingSelected: (isEverythingSelected: boolean) => void
  setSorter: (sorter: PaymentOrderSorter) => void
  setSelectedPaymentOrders: (selectedPaymentOrders: SelectionKeys) => void
  onPaymentOrdersPageSuccess: (params: onPaymentOrdersPageSuccessParams) => void
  setPaymentOrderToView: (paymentOrderToView: PaymentOrder) => void
  updatePaymentOrder: (paymentOrder: PaymentOrder) => void
  setSearch: (search: string) => void
  setIsSearching: (isSearching: boolean) => void
  setFilters: (filters: Record<string, string[]>) => void
  setStatus: (status: PaymentOrderStatus) => void
}

export const usePaymentOrdersDashboardStore = create<PaymentOrdersDashboardState>()(set => ({
  isFetchingPaymentOrders: false,
  isSearching: false,
  isMakePaymentsModalOpened: false,
  isErrorModalOpened: false,
  isMakingPayments: false,
  isEverythingSelected: false,
  sorter: null,
  selectedPaymentOrders: [],
  paymentOrders: [],
  allPaymentOrdersCount: 0,
  paymentOrderToView: null,
  currentPage: 1,
  search: '',
  filters: {},
  status: null,
  setIsFetchingPaymentOrders: (isFetchingPaymentOrders: boolean) => set({ isFetchingPaymentOrders }),
  setIsSearching: (isSearching: boolean) => set({ isSearching }),
  setIsMakePaymentsModalOpened: (isMakePaymentsModalOpened: boolean) => set({ isMakePaymentsModalOpened }),
  setIsErrorModalOpened: (isErrorModalOpened: boolean) => set({ isErrorModalOpened }),
  setIsMakingPayments: (isMakingPayments: boolean) => set({ isMakingPayments }),
  setIsEverythingSelected: (isEverythingSelected: boolean) => set({ isEverythingSelected }),
  setSorter: (sorter: PaymentOrderSorter) => set({ sorter }),
  setSelectedPaymentOrders: (selectedPaymentOrders: SelectionKeys) => {
    set({ selectedPaymentOrders })
    set({ isEverythingSelected: false })
  },
  onPaymentOrdersPageSuccess: ({
    paymentOrders,
    allPaymentOrdersCount,
    currentPage
  }: onPaymentOrdersPageSuccessParams) =>
    set({
      paymentOrders,
      allPaymentOrdersCount,
      currentPage,
      isFetchingPaymentOrders: false,
      isSearching: false
    }),
  setPaymentOrderToView: (paymentOrderToView: PaymentOrder) => set({ paymentOrderToView }),
  updatePaymentOrder: (paymentOrder: PaymentOrder) =>
    set((state: PaymentOrdersDashboardState) => {
      const updatedPaymentOrders = [...state.paymentOrders]
      const paymentOrderIndex = updatedPaymentOrders.findIndex(p => p.id === paymentOrder.id)
      updatedPaymentOrders[paymentOrderIndex] = { ...paymentOrder, key: paymentOrder.id }
      return { paymentOrders: updatedPaymentOrders }
    }),
  setSearch: (search: string) => set({ search, isSearching: true }),
  setStatus: (status: PaymentOrderStatus) => set({ status }),
  setFilters: (filters: Record<string, string[]>) => set({ filters })
}))
