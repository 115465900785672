import './PaymentOrderStatusSelect.less'

import { Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'

import { PaymentOrderStatus } from '../../../requests'
import { DatacColorStatusSelect, DatacIcon } from '../../common'

interface Props {
  status: PaymentOrderStatus
  statusDetails?: string
}
export const PaymentOrderStatusSelect: React.VFC<Props> = ({ status, statusDetails }) => {
  const [currentStatus, setCurrentStatus] = useState(status)

  const getIcon = useCallback((status: PaymentOrderStatus) => {
    switch (status) {
      case PaymentOrderStatus.Processing:
        return <DatacIcon raw name="refresh" />
      case PaymentOrderStatus.Error:
        return <DatacIcon raw name="xCircle" />
      case PaymentOrderStatus.Paid:
        return <DatacIcon raw name="checkCircle" />
      default:
        return <DatacIcon raw name="refresh" />
    }
  }, [])

  useEffect(() => {
    setCurrentStatus(status)
  }, [status])

  const select = (
    <div className="payment-order-status">
      <DatacColorStatusSelect<PaymentOrderStatus> status={currentStatus} disabled />
      {getIcon(currentStatus)}
    </div>
  )

  if (currentStatus === PaymentOrderStatus.Error && statusDetails) {
    return (
      <Tooltip title={statusDetails} placement="top">
        {select}
      </Tooltip>
    )
  }
  return select
}
