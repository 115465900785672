import { Form, Input } from 'antd'
import React, { useEffect } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { bulkInvalidatePaymentOrders } from '../../../../requests'
import { validateRequired } from '../../../../validation'
import { DatacFormItem, DatacMessage, DatacModal } from '../../../common'
import { usePaymentOrdersDashboardStore } from '../PaymentOrdersDashboardStore'

interface SetErrorModalProps {
  isOpened: boolean
  onClose: () => void
}

export const SetErrorModal: React.FC<SetErrorModalProps> = ({ onClose, isOpened }) => {
  const intlModal = useScopedIntl('payment_orders.set_error.modal')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()

  const { selectedPaymentOrders } = usePaymentOrdersDashboardStore()

  useEffect(() => {
    if (isOpened) {
      formInstance.resetFields()
    }
  }, [isOpened])

  const onSetError = ({ details }: { details: string }) => {
    bulkInvalidatePaymentOrders(
      {
        records: selectedPaymentOrders,
        details
      },
      {
        onSuccess: onClose,
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  return (
    <DatacModal
      title={intl('payment_orders.set_error')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={() => formInstance.submit()}
    >
      <Form form={formInstance} onFinish={onSetError} name="payment-orders-error-modal-form">
        <DatacFormItem
          name="details"
          label={intlModal('details.label')}
          validate={validateRequired(intl('common.required'))}
        >
          <Input size="large" placeholder={intlModal('details.placeholder')} />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
