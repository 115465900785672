import './PaymentOrdersDashboardStats.less'

import React, { useImperativeHandle, useMemo, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { PaymentOrderStats, PaymentOrderStatus, fetchPaymentOrderStats } from '../../../../requests'
import { DatacBox, DatacLoading, DatacMessage } from '../../../common'
import { PaymentsDashboardStatsCell } from '../../PaymentsDashboard'
import { usePaymentOrdersDashboardStore } from '../PaymentOrdersDashboardStore'

export interface PaymentOrdersDashboardStatsRefType {
  triggerReloadKpis: () => void
}

export const PaymentOrdersDashboardStats = React.forwardRef<PaymentOrdersDashboardStatsRefType, {}>(
  (_: unknown, ref) => {
    const intl = useScopedIntl('')
    const intlStatus = useScopedIntl('status')
    const [isLoading, setIsLoading] = useState(true)
    const [stats, setStats] = useState<PaymentOrderStats>(null)
    const { setFilters, search, filters } = usePaymentOrdersDashboardStore()

    const fetchStats = () => {
      setIsLoading(true)
      fetchPaymentOrderStats(
        {
          options: {
            search,
            filters: {
              status: filters?.status,
              center: filters?.center,
              recruitment_reference: filters?.recruitment_reference
            }
          }
        },
        {
          onSuccess: stats => {
            setStats(stats)
            setIsLoading(false)
          },
          onRequestError: code => {
            DatacMessage.genericError(intl, code)
            setIsLoading(false)
          }
        }
      )
    }

    useImperativeHandle(ref, () => ({
      triggerReloadKpis: () => {
        fetchStats()
      }
    }))

    const content = useMemo(() => {
      if (stats === null) return null
      return (
        <>
          <PaymentsDashboardStatsCell
            icon="refresh"
            label={intlStatus('processing')}
            name="processing"
            values={stats.processing}
            onClick={() => setFilters({ status: [PaymentOrderStatus.Processing] })}
          />
          <PaymentsDashboardStatsCell
            icon="xCircle"
            label={intlStatus('error')}
            name="error"
            values={stats.error}
            onClick={() => setFilters({ status: [PaymentOrderStatus.Error] })}
          />
          <PaymentsDashboardStatsCell
            icon="checkCircle"
            label={intlStatus('paid')}
            name="paid"
            values={stats.paid}
            onClick={() => setFilters({ status: [PaymentOrderStatus.Paid] })}
          />
          <PaymentsDashboardStatsCell
            icon="globe"
            label={intl('payments.stats.global')}
            name="global"
            values={stats.total}
            onClick={() => setFilters({ status: [] })}
          />
        </>
      )
    }, [stats])

    return (
      <DatacLoading isLoading={isLoading}>
        <DatacBox className="payment-orders-dashboard__stats">{content}</DatacBox>
      </DatacLoading>
    )
  }
)
