import { Form, Switch } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { ExportFileFormat, SelectionKeys, exportPaymentOrders } from '../../../../requests'
import { DatacFormItem, DatacMessage, DatacModal, DatacSelect } from '../../../common'

interface PaymentsExportProps {
  search: string
  filters: Record<string, string[]>
  records: SelectionKeys
  isOpened: boolean
  onClose: () => void
  isEverythingSelected: boolean
}

export const PaymentOrdersExport: React.FC<PaymentsExportProps> = ({
  onClose,
  search,
  filters,
  records,
  isOpened,
  isEverythingSelected
}) => {
  const intlModal = useScopedIntl('subject_repository.export.modal')
  const intl = useScopedIntl('')
  const [isExporting, setIsExporting] = useState(false)
  const [formInstance] = Form.useForm()
  const cancelExportRef = useRef<() => void>(() => null)
  const [exportFiles, setExportFiles] = useState(false)

  useEffect(() => {
    if (isOpened) {
      formInstance.resetFields()
      setExportFiles(false)
    } else {
      cancelExportRef.current()
      setIsExporting(false)
    }
  }, [isOpened])

  const onExport = ({ exportFileFormat }: { exportFileFormat: ExportFileFormat }) => {
    setIsExporting(true)
    const cancel = exportPaymentOrders(
      {
        exportFileFormat,
        exportFiles,
        search,
        records: records?.length && !isEverythingSelected ? records : undefined,
        status: filters?.status
      },
      {
        onSuccess: () => {
          setIsExporting(false)
          onClose()
        },
        onRequestError: code => {
          setIsExporting(false)
          DatacMessage.genericError(intl, code)
        }
      }
    )

    cancelExportRef.current = cancel
  }

  const exportFileFormatOptions = [
    { label: intl('common.file_format.csv'), value: ExportFileFormat.Csv },
    { label: intl('common.file_format.excel'), value: ExportFileFormat.Excel }
  ]

  return (
    <DatacModal
      title={intlModal('title')}
      isOpened={isOpened}
      onClose={onClose}
      onSubmit={() => formInstance.submit()}
      submitLabel={intlModal('submit')}
      loading={isExporting}
    >
      <Form form={formInstance} onFinish={onExport} name="payment-orders-export-modal-form">
        <DatacFormItem
          name="exportFileFormat"
          label={intlModal('export_file_format.label')}
          initialValue={ExportFileFormat.Csv}
        >
          <DatacSelect options={exportFileFormatOptions} />
        </DatacFormItem>
        <DatacFormItem label={intlModal('export_files.label')}>
          <Switch checked={exportFiles} onChange={() => setExportFiles(!exportFiles)} />
        </DatacFormItem>
      </Form>
    </DatacModal>
  )
}
